import React from 'react';
import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import YoutubePlayer from './Components/YoutubePlayer/YoutubePlayer';
import { PlayerProvider } from './Context/PlayerContext';

function App() {
  return (
    <div className="App">
      <PlayerProvider>
        <YoutubePlayer></YoutubePlayer>
      </PlayerProvider>
    </div>
  );
}

export default App;
