const Actions = {
    LOAD_VIDEOS: 'load_videos',
    IS_LOADING_VIDEOS: 'is_loading_vidoes',
    LOAD_PLAYER: 'load_player',
    PLAYER_READY: 'player_ready',
    PLAYER_STATE_CHANGE: 'player_state_change',
    FETCH_YOUTUBE_DATA: 'fetch_youtube_data',
    UPDATE_MARK_SEGMENT: 'save_mark_segment',
    UPDATE_SLIDER_MARK: 'update_slider_mark',
    UPDATE_CURRENT_VIDEO: 'update_current_video',
    UPDATE_VIDEO_CURRENT_TIME: 'update_video_current_time',
    UPDATE_MARK_STARTED: 'update_mark_stated',
    UPDATE_SLIDER_VALUE: 'update_slider_value',
    UPDATE_PAGE: 'update_page',
    UPDATE_LIMIT: 'update_limit',
    UPDATE_SORT: 'update_sort',
    UPDATE_ORDER: 'update_order',
    UPDATE_SEARCH: 'update_search'
};

export default Actions;