import React, { useState, useEffect, useContext } from 'react';
import { PlayerStateContext, PlayerDispatchContext } from '../../Context/PlayerContext';
import Videos from './VideoList/Videos';
import { Input, Button, message, notification } from 'antd';
import Player from './Player';
import Sliders from './Sliders';
import {
  CaretRightOutlined, PauseOutlined,
  StepBackwardOutlined, StepForwardOutlined, FastBackwardOutlined, FastForwardOutlined,
  FlagOutlined, FlagFilled, CloseSquareOutlined, SaveOutlined
} from '@ant-design/icons';
import Actions from '../../Reducers/Actions';
import SegmentTable from './SegmentTable';
import VideoUtility from './Utilities/VideoUtility';
import { Video } from '../../Models/Video';
import { useReactMediaRecorder } from "react-media-recorder";

const { Search } = Input;

const YoutubePlayer: React.FC = () => {

  const { status, startRecording, stopRecording, mediaBlobUrl, unMuteAudio } =
    useReactMediaRecorder({ video: false, audio: true, screen: false, blobPropertyBag: { type: 'audio/mp3' } });
  const { state } = useContext(PlayerStateContext);
  const { dispatch } = useContext(PlayerDispatchContext);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [fetchVideo, setFetchVideo] = useState(false);

  useEffect(() => {
    setFetchVideo(false);
  }, [youtubeUrl]);

  useEffect(() => {
    if(!mediaBlobUrl) return;

    let index = state.markSegments.length - 1;
    let time = state.player!.getCurrentTime();
    dispatch({ type: Actions.UPDATE_MARK_STARTED, payload: false });
    state.markSegments[index].end = time;
    state.markSegments[index].audio = mediaBlobUrl;
    dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
    notification.success({
      message: "Segment successfully saved.",
      duration: 5
    });
  }, [mediaBlobUrl]);

  const searchYoutubeVideo = (value: string) => {
    setFetchVideo(true);
  }

  const playVideo = () => {
    state.player?.playVideo();
    dispatch({ type: Actions.PLAYER_STATE_CHANGE, payload: true });
  }

  const pauseVideo = () => {
    state.player?.pauseVideo();
    dispatch({ type: Actions.PLAYER_STATE_CHANGE, payload: false });
  }

  const backVideo = (time: number) => {
    let currentTime = state.player ? state.player.getCurrentTime() : 0;
    if (currentTime > time)
      state.player?.seekTo(currentTime - time);
    else
      state.player?.seekTo(0);
  }

  const forwardVideo = (time: number) => {
    let currentTime = state.player ? state.player.getCurrentTime() : 0;
    if (currentTime < state.videoDuration)
      state.player?.seekTo(currentTime + time);
    else
      state.player?.seekTo(state.videoDuration);
  }

  const startGetSegment = () => {
    let time = state.player!.getCurrentTime();
    dispatch({ type: Actions.UPDATE_MARK_STARTED, payload: true });
    let timeObj = {
      start: time,
      end: null,
      note: ''
    }

    startRecording();
    let segments = state.markSegments;
    segments.push(timeObj);
    dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: segments });
    state.player!.playVideo();
    
  }

  const endGetSegment = () => {
    stopRecording();
    // saveVideo(); // save the video after adding a segement
  }

  const saveVideo = () => {
    console.log(state.youtubeData);
    let data = {
      youtube_id: state.currentVideoId!,
      title: state.youtubeData!.title,
      description: state.youtubeData!.description,
      url: `https://www.youtube.com/watch?v=${state.currentVideoId}`,
      thumbnail: state.youtubeData!.images ? state.youtubeData!.images[0] : "",
      segments: state.markSegments
    }
    if (state.currentVideo) {
      let vid = VideoUtility.updateVideo(state.currentVideo!.id!, data);
      vid.then(
        (video: Video) => {
          dispatch({ type: Actions.UPDATE_CURRENT_VIDEO, payload: video });
          message.success("Video successfully saved.");
        },
        (err) => message.error(err)
      );

    } else {
      let vid = VideoUtility.saveVideo(data);
      vid.then(
        (video: Video) => {
          dispatch({ type: Actions.UPDATE_CURRENT_VIDEO, payload: video });
          message.success("Video successfully saved.");
          state.videoData?.data!.unshift(video);
          dispatch({ type: Actions.LOAD_VIDEOS, payload: state.videoData });
        },
        (err) => message.error(err)
      )
    }
  }

  return (
    <div>
      <div className="content" style={{ height: "100vh" }}>
        <div className="player-container">
          <div className="video-container">
            <Videos></Videos>
            <div style={{ flex: 1, padding: "0 20px 0 20px" }}>
              <Player url={youtubeUrl} fetchVideo={fetchVideo}></Player>
              <div>
                <Search value={youtubeUrl} placeholder="Enter youtube url..."
                  style={{ width: 450 }} allowClear enterButton="Load Video"
                  onChange={(e) => setYoutubeUrl(e.target.value)} onSearch={searchYoutubeVideo} />
              </div>
              {(state.player && state.videoLoaded) && <div className="playback-buttons">
                <Button type="link" onClick={() => backVideo(60)} icon={<FastBackwardOutlined />} title="Back 1 minute"></Button>
                <Button type="link" onClick={() => backVideo(10)} icon={<StepBackwardOutlined />} title="Back 10 seconds"></Button>
                {!state.videoIsPlaying && <Button type="link" onClick={playVideo} icon={<CaretRightOutlined />} title="Play"></Button>}
                {state.videoIsPlaying && <Button type="link" onClick={pauseVideo} icon={<PauseOutlined />} title="Pause"></Button>}
                <Button type="link" onClick={() => forwardVideo(10)} icon={<StepForwardOutlined />} title="Forward 10 seconds"></Button>
                <Button type="link" onClick={() => forwardVideo(60)} icon={<FastForwardOutlined />} title="Forward 1 minute"></Button>
                { /*<Button type="link" onClick={stopVideo} icon={<CloseSquareOutlined />} title="Stop"></Button>*/}
              </div>}
              {(state.player && state.videoLoaded) && <div className="control-buttons">
                {!state.markStarted && <Button type="primary" onClick={startGetSegment} icon={<FlagOutlined />}>Mark Segment</Button>}
                {state.markStarted && <Button type="primary" danger onClick={endGetSegment} icon={<FlagFilled />}>Stop Marking</Button>}
                <Button type="primary" onClick={saveVideo} icon={<SaveOutlined />}>Save Video</Button>
              </div>}
              {state.youtubeData && <div>
                <a href={state.youtubeData.url} target="_blank"><h3>{state.youtubeData.title}</h3></a>
              </div>}
              <Sliders></Sliders>
              <SegmentTable></SegmentTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YoutubePlayer;
