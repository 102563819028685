import React, { MouseEventHandler } from 'react';
import { Video } from '../../../Models/Video';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
    video: Video,
    onClick: MouseEventHandler,
    deleteVideo: any,
    index?: number
}

const VideoCard: React.FC<Props> = ({ video, onClick, deleteVideo, index }: Props) => {

    const removeButtonHandler = () => {
        deleteVideo(video, index)
    }

    return (
        <div className="video-card">
            <div style={{cursor: "pointer", marginBottom: 15}} key={video.id} onClick={onClick}>
                {/* <img src={video.thumbnail} style={{width: "100%"}} alt={video.description} /> */}
                <p>{video.title}</p>
            </div>
            <Button className="close-button" danger icon={<CloseOutlined />} 
                shape="circle" onClick={removeButtonHandler} />
        </div>
    );
}

export default VideoCard;