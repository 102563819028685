

class UrlValidator {
  validURL = (str: string): boolean => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  isYoutubeUrl = (youTubeURl: string): boolean => {
    var success = false;
    var pattern = new RegExp(/^(http(s)?:\/\/)?(((w){3}|m).)?youtu(be|.be)?(\.com)?\/.+/);
    if (pattern.test(youTubeURl)) {
      success = true;
    } else {
      // Not Valid youtube URL
      success = false;
    }

    return success;
  }

  getVideoIdFromYoutubeUrl = (youtubeUrl: string): string => {
    /*
        Possibile Youtube urls.
        http://www.youtube.com/watch?v=WK0YhfKqdaI
        http://www.youtube.com/embed/WK0YhfKqdaI
        http://www.youtube.com/v/WK0YhfKqdaI
        http://www.youtube-nocookie.com/v/WK0YhfKqdaI?version=3&hl=en_US&rel=0
        http://www.youtube.com/watch?v=WK0YhfKqdaI
        http://www.youtube.com/watch?feature=player_embedded&v=WK0YhfKqdaI
        http://www.youtube.com/e/WK0YhfKqdaI
        http://youtu.be/WK0YhfKqdaI
     */
    var video_id = youtubeUrl.split('v=')[1];
    if (video_id) {
      var ampersandPosition = video_id.indexOf('&');
      if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
    } else {
      let parts = youtubeUrl.split("/");
      video_id = parts[parts.length -1];
    }

    return video_id;
  }
}

export default new UrlValidator();