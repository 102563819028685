import React, { createContext, Dispatch, useReducer } from 'react';
import { reducer, State } from '../Reducers/VideoReducer';

const initialState: State = {
    videos: [],
    loadingVideos: false,
    markStarted: false,
    markSegments: [],
    videoLoaded: false,
    videoIsPlaying: false,
    sliderMarks: {},
    videoDuration: 0,
    sliderValue: [],
    currentVideoTime: 0
}

interface IVideoStateContext {
    state: State
}

interface IDispatchContext {
    dispatch: Dispatch<any> 
}

export const PlayerStateContext = createContext<IVideoStateContext>({state: initialState});
export const PlayerDispatchContext = createContext<IDispatchContext>({ dispatch: () => {} });

export const PlayerProvider: React.FC = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <PlayerStateContext.Provider value={{ state }}>
            <PlayerDispatchContext.Provider value={{ dispatch }}>
                {children}
            </PlayerDispatchContext.Provider>
        </PlayerStateContext.Provider>
    );
}
