import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { PlayerStateContext, PlayerDispatchContext } from '../../Context/PlayerContext';
import UrlValidator from './Utilities/UrlValidator';
import Actions from '../../Reducers/Actions';
import VideoUtility from './Utilities/VideoUtility';
import YoutubeMediaPlayer from './Utilities/YoutubeMediaPlayer';

interface Props {
    url: string,
    fetchVideo: boolean
}

const Player: React.FC<Props> = ({ url, fetchVideo }: Props ) => {
    const { state } = useContext(PlayerStateContext);
    const { dispatch } = useContext(PlayerDispatchContext);
    const [videoTimeInterval, setVideoTimeInterval] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if(fetchVideo && url.length > 0)
            loadVideo(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchVideo]);

    useEffect(() => {
        loadVideo(state.currentVideo?.url!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentVideo]);

    useEffect(() => {
        if(state.videoIsPlaying) {
            let interval = setInterval(() => {
                if(state.player && state.videoIsPlaying) {
                    // console.log(player.getCurrentTime());
                    dispatch({type: Actions.UPDATE_VIDEO_CURRENT_TIME, payload: state.player.getCurrentTime()});
                }
            }, 300);
            setVideoTimeInterval(interval);
        } else {
            if(videoTimeInterval)
                clearTimeout(videoTimeInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.videoIsPlaying]);

    const onPlayerStateChange = (event: any) => {
        dispatch({type: Actions.PLAYER_STATE_CHANGE, payload: event.data === 1});
    }

    const onPlayerReady = (event: any) => {
        dispatch({type: Actions.PLAYER_READY, payload: event.target.getDuration()});
        let youtubeData = {
          title: event.target.getVideoData().title,
          description: event.target.getVideoData().title,
        }

        dispatch({type: Actions.FETCH_YOUTUBE_DATA, payload: youtubeData})
    }

    const loadVideo = (url: string): void => {
        if(!url)
            return;
            
        if(!UrlValidator.validURL(url) || !UrlValidator.isYoutubeUrl(url)) {
            message.error("Invalid youtube url");
            return;
        }

        var youtubueId = UrlValidator.getVideoIdFromYoutubeUrl(url);

        if(state.player && typeof state.player.loadVideoById === 'function') {
          // console.log(state.player);
            state.player.loadVideoById({ videoId: youtubueId });
            // dispatch({type: Actions.LOAD_PLAYER, payload: 
            //     {player: YoutubeMediaPlayer.getPlayer(), youtubueId: youtubueId}});
        } else {
            YoutubeMediaPlayer.setPlayer('video-player', {
                height: '390',
                width: '640',
                videoId: youtubueId,
                playerVars: {
                    playsinline: 1
                },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange
                }
            });

            // console.log(YoutubeMediaPlayer.getPlayer());
            dispatch({type: Actions.LOAD_PLAYER, payload: 
                {player: YoutubeMediaPlayer.getPlayer(), youtubueId: youtubueId}});
        }

        // let youtubeData = VideoUtility.scrapeYoutubeVideo(url);
        // youtubeData.then(
        //     (data) => dispatch({type: Actions.FETCH_YOUTUBE_DATA, payload: data}),
        //     (err) => message.error("Unable to fetch video data!")
        // );

        if(!state.currentVideo || state.currentVideo?.youtube_id !== youtubueId) {
            let videoData = VideoUtility.getVideoInfo(youtubueId);
            console.log("video data", videoData);
            videoData.then(
                (data) => dispatch({type: Actions.UPDATE_CURRENT_VIDEO, payload: data}),
                (error) => dispatch({type: Actions.UPDATE_CURRENT_VIDEO, payload: null})
            );
        }
    }

    return (
        <div id="video-player"></div>
    );
}

export default Player;