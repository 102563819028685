import Actions from "../../../Reducers/Actions";

// state definition
export interface State {
    limit: number,
    sort_by: string,
    order: string,
    search: string,
    page: number
}

export const reducer = (state: State, action: any): State => {
    switch(action.type) {
        case Actions.UPDATE_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case Actions.UPDATE_PAGE:
            return {
                ...state,
                page: action.payload.page,
                limit: action.payload.limit
            };
        case Actions.UPDATE_SORT:
            return {
                ...state,
                sort_by: action.payload
            };
        case Actions.UPDATE_ORDER:
            return {
                ...state,
                order: action.payload
            };
        default:
            return {...state};
    }
}