import BaseClient from "./BaseClient";
import { AxiosResponse } from 'axios';

class Scraper extends BaseClient {

    scrape = (url: string): Promise<AxiosResponse<any>> => {
        url = encodeURIComponent(url);

        return this.get(`/scrape/youtube?url=${url}`);
    }

}

export default new Scraper();