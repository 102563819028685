import Actions from "./Actions"
import { Video } from "../Models/Video";
import { Paginator } from "../Models/Paginator";
import { YoutubeMediaPlayer } from "../Components/YoutubePlayer/Utilities/YoutubeMediaPlayer";
import { Segment } from "../Models/Segment";
import { ScrapeData } from "../Models/ScrapeData";
import { SliderMarks } from "antd/lib/slider";

// state definition
export interface State {
    videos: Array<Video>,
    videoData?: Paginator,
    loadingVideos: boolean,
    player?: YoutubeMediaPlayer,
    markStarted: boolean,
    markSegments: Array<Segment>,
    videoLoaded: boolean,
    videoIsPlaying: boolean,
    sliderMarks: any,
    videoDuration: number,
    sliderValue: any,
    currentVideoId?: string,
    youtubeData?: ScrapeData,
    currentVideo?: Video,
    currentVideoTime: number
}

// video reducer function
export const reducer = (state: State, action: any): State => {
    switch(action.type) {
        case Actions.LOAD_VIDEOS: // load all the videos from the server
            return {
                ...state,
                videos: action.payload.data,
                videoData: action.payload,
                loadingVideos: false
            };
        case Actions.IS_LOADING_VIDEOS: // set if the videos are loading
            return {
                ...state,
                loadingVideos: action.payload
            };
        case Actions.LOAD_PLAYER: // trigger this action when the video player is loaded
            return {
                ...state,
                player: action.payload.player,
                currentVideoId: action.payload.youtubueId,
                sliderMarks: [],
                sliderValue: [0, 0]
            }
        case Actions.PLAYER_READY: // trigger this action when youtube video is loaded
            return {
                ...state,
                videoLoaded: true,
                videoDuration: action.payload
            }
        case Actions.PLAYER_STATE_CHANGE:
            return {
                ...state,
                videoIsPlaying: action.payload
            }
        case Actions.FETCH_YOUTUBE_DATA:
            return {
                ...state,
                youtubeData: action.payload
            }
        case Actions.UPDATE_CURRENT_VIDEO:
            return {
                ...state,
                currentVideo: action.payload,
                markSegments: action.payload ? [...action.payload.segments] : []
            }
        case Actions.UPDATE_VIDEO_CURRENT_TIME:
            return {
                ...state,
                currentVideoTime: action.payload
            }
        case Actions.UPDATE_MARK_SEGMENT:
            console.log(action.payload);
            return {
                ...state,
                markSegments: [...action.payload]
            }
        case Actions.UPDATE_SLIDER_MARK:
            return {
                ...state,
                sliderMarks: action.payload
            }
        case Actions.UPDATE_MARK_STARTED:
            return {
                ...state,
                markStarted: action.payload
            }
        case Actions.UPDATE_SLIDER_VALUE:
            return {
                ...state,
                sliderValue: action.payload
            }
        default:
            return {...state};
    }
}