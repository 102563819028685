// declare YT (youtube) object as global
declare global {
    interface Window {
        YT: any
    }
}

interface Event {
    target: JSX.Element,
    data: any
}

interface Player {
    height: string,
    width: string,
    videoId: string,
    playerVars?: {
        playsinline?: number
    },
    events: {
        onReady?(event: Event): void,
        onStateChange?(event: Event): void,
        onPlaybackQualityChange?(event: Event): void,
        onPlaybackRateChange?(event: Event): void,
        onError?(event: Event): void,
        onApiChange?(event: Event): void,
    }
}

export class YoutubeMediaPlayer {
    player: any = null;

    setPlayer = (id: string, player: Player) => {
        var pl = new window.YT.Player(id, player);
        this.player = pl;
    }

    getPlayer = (): any => {
        return this.player;
    }
    
    /**************************************************/
    /*          LOADING VIDEOS FUNCTIONS              */
    /**************************************************/

    loadVideoById = (params: {
        videoId: string,
        startSeconds?: number,
        endSeconds?: number
    } ): void => {
        this.player.loadVideoById(params);
    }

    cueVideoById = (params: {
        videoId: string,
        startSeconds?: number,
        endSeconds?: number
    } ): void => {
        this.player.cueVideoById(params);
    }

    cueVideoByUrl = (params: {
        mediaContentUr: string,
        startSeconds?: number,
        endSeconds?: number
    } ): void => {
        this.player.cueVideoByUrl(params);
    }

    loadVideoByUrl = (params: {
        mediaContentUr: string,
        startSeconds?: number,
        endSeconds?: number
    } ): void => {
        this.player.loadVideoByUrl(params);
    }

    cuePlaylist = (params: {
        listType: string,
        list: string,
        index?: number,
        startSeconds?: number
    } ): void => {
        this.player.cuePlaylist(params);
    }

    loadPlaylist = (params: {
        listType: string,
        list: string,
        index?: number,
        startSeconds?: number
    } ): void => {
        this.player.loadPlaylist(params);
    }

    /**************************************************/
    /*          PLAYING VIDEOS FUNCTIONS              */
    /**************************************************/

    playVideo = (): void => {
        this.player.playVideo();
    }

    pauseVideo = (): void => {
        this.player.pauseVideo();
    }

    stopVideo = (): void => {
        this.player.stopVideo();
    }

    seekTo = (seconds: number, allowSeekAhead?: boolean): void => {
        this.player.seekTo(seconds, allowSeekAhead);
    }

    nextVideo = (): void => {
        this.player.nextVideo();
    }

    previousVideo = (): void => {
        this.player.previousVideo();
    }

    playVideoAt = (index: number): void => {
        this.player.playVideoAt(index);
    }

    /**************************************************/
    /*          CHANGING VOLUME FUNCTIONS             */
    /**************************************************/

    mute = (): void => {
        this.player.mute();
    }

    unMute = (): void => {
        this.player.unMute();
    }

    isMuted = (): boolean => {
        return this.player.isMuted();
    }

    setVolume = (volulme: number): void => {
        this.player.setVolume(volulme);
    }

    getVolume = (): number => {
        return this.player.getVolume();
    }

    /**************************************************/
    /*          SETTING PLAYER SIZE FUNCTIONS         */
    /**************************************************/

    setSize = (width: number, height: number): void => {
        this.player.setSize(width, height);
    }

    /**************************************************/
    /*          PLAYBACK RATE FUNCTIONS               */
    /**************************************************/

    getPlaybackRate = (): number => {
        return this.player.getPlaybackRate();
    }

    setPlaybackRate = (suggesterRate: number): void => {
        this.player.setPlaybackRate(suggesterRate);
    }

    getAvailablePlaybackRates = (): Array<any> => {
        return this.player.getAvailablePlaybackRates();
    }

    setLoop = (loopPlaylists: boolean): void => {
        this.player.setLoop(loopPlaylists);
    }

    setShuffle = (shufflePlaylist: boolean): void => {
        this.player.setShuffle(shufflePlaylist);
    }

    /**************************************************/
    /*          PLAYBACK STATUS FUNCTIONS             */
    /**************************************************/

    getPlayerState = (): number => {
        return this.player.getPlayerState();
    }

    getVideoLoadedFraction = (): number => {
        return this.player.getVideoLoadedFraction();
    }

    getCurrentTime = (): number => {
        return this.player.getCurrentTime();
    }

    /**************************************************/
    /*          VIDEO INFORMATION FUNCTIONS           */
    /**************************************************/

    getDuration = (): number => {
        return this.player.getDuration();
    }

    getVideoUrl = (): string => {
        return this.player.getVideoUrl();
    }

    getVideoEmbedCode = (): string => {
        return this.player.getVideoEmbedCode();
    }

    /**************************************************/
    /*          PLAYLIST INFORMATION FUNCTIONS        */
    /**************************************************/

    getPlaylist = (): Array<any> => {
        return this.player.getPlaylist();
    }

    getPlaylistIndex = (): number => {
        return this.player.getPlaylistIndex();
    }
}

export default new YoutubeMediaPlayer();