import React, { useContext, useEffect } from 'react';
import { Slider, Popover } from 'antd';
import { PlayerStateContext, PlayerDispatchContext } from '../../Context/PlayerContext';
import Actions from '../../Reducers/Actions';
import TimeSpan from './Utilities/TimeSpan';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const Sliders: React.FC = () => {
    const { state } = useContext(PlayerStateContext);
    const { dispatch } = useContext(PlayerDispatchContext);

    useEffect(() => {
        let marks: { style: { color: string; }; label: JSX.Element; }[] = [];

        state.markSegments.forEach((mark) => {
            marks[mark.start] = {
                style: {
                    color: "#32CD32"
                },
                label: <p><Popover content={`Start (${TimeSpan.secondsToHms(mark.start)})`}><CaretLeftOutlined /></Popover></p>
            }

            if(mark.end) {
                marks[mark.end] = {
                    style: {
                        color: "#FF4500"
                    },
                    label: <p><Popover content={`End (${TimeSpan.secondsToHms(mark.end)})`}><CaretRightOutlined /></Popover></p>
                }
            }
        });

        dispatch({type: Actions.UPDATE_SLIDER_MARK, payload: marks});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.markSegments]);

    const videoTimeSliderChangeHandler = (value: number) => {
        dispatch({type: Actions.PLAYER_STATE_CHANGE, payload: false});
        state.player?.seekTo(value);
        dispatch({type: Actions.UPDATE_VIDEO_CURRENT_TIME, payload: value});
    }

    const setVideoPlayerTime = (value: number) => {
        dispatch({type: Actions.UPDATE_VIDEO_CURRENT_TIME, payload: value});
        state.player?.playVideo();
        dispatch({type: Actions.PLAYER_STATE_CHANGE, payload: true});
    }

    return (
        <div>
            <p>{TimeSpan.secondsToHms(state.currentVideoTime)}</p>
            <Slider value={state.currentVideoTime} onChange={videoTimeSliderChangeHandler}
                onAfterChange={setVideoPlayerTime}
                max={state.videoDuration} step={0.01}></Slider>
            <Slider marks={state.sliderMarks} value={state.sliderValue} range 
                max={state.videoDuration} step={0.01}></Slider>
        </div>
    );
}

export default Sliders;