
class TimeSpan {

    secondsToHms = (d: number | null): string => {
        if(!d)
            return `${d}`;

        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? `${(h).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:` : "";
        var mDisplay = `${(m).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:`;
        var sDisplay = `${(s).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}`;
        return `${hDisplay}${mDisplay}${sDisplay}`; 
    }

    hmsToSeconds = (time: string): number => {
      const timeArray: Array<number> = time.split(":").map((val): number => {
        return parseInt(val);
      });
      console.log(timeArray);
      const seconds = timeArray.length > 2 ? timeArray[0]*3600+timeArray[1]*60+(+timeArray[2]) : timeArray[0]*60+(+timeArray[1]);
      console.log(seconds);

      return seconds;
    }
}

export default new TimeSpan();