import React, { useState, useContext, useEffect, useReducer } from 'react';
import { Input, message, Modal, Pagination, Skeleton } from 'antd';
import { PlayerDispatchContext, PlayerStateContext } from '../../../Context/PlayerContext';
import VideoUtility from '../Utilities/VideoUtility';
import Actions from '../../../Reducers/Actions';
import { reducer as listReducer } from './VideoListReducer';
import { Video } from '../../../Models/Video';
import VideoCard from './VideoCard';
import { ExclamationOutlined } from '@ant-design/icons';

const { Search } = Input;

const Videos: React.FC = () => {
    let initialState = {
        limit: 15,
        sort_by: "created_at",
        order: "DESC",
        search: '',
        page: 1
    };

    const [listState, listDispatch] = useReducer(listReducer, initialState);
    const { state } = useContext(PlayerStateContext);
    const { dispatch } = useContext(PlayerDispatchContext);
    const [search, setSearch] = useState<string>();

    // useEffect(() => {
    //     loadVideos();
    // }, []);

    useEffect(() => {
        loadVideos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listState]);

    const loadVideos = async () => {
        dispatch({type: Actions.IS_LOADING_VIDEOS, payload: true});
        let videos = VideoUtility.loadVideos(listState);
        videos.then((data: any) => {
            dispatch({type:  Actions.LOAD_VIDEOS, payload: data });
        }, (error) => message.error(error));
    }

    const searchVideos = (search: string) => {
        listDispatch({type: Actions.UPDATE_SEARCH, payload: search });
    }

    const paginatorSizeChange = (page: number, limit: number) => {
        listDispatch({type: Actions.UPDATE_PAGE, payload: { page, limit }});
    }

    const paginatorPageChange = (page: number, limit?: number) => {
        listDispatch({type: Actions.UPDATE_PAGE, payload: { page, limit }});
    }

    const handleVideoClick = (video: Video) => {
        dispatch({type: Actions.UPDATE_CURRENT_VIDEO, payload: video});
    }

    const handleRemoveVideo = (video: Video, index: number) => {
        Modal.confirm({
            title: `Delete Video`,
            icon: <ExclamationOutlined />,
            content: `Are you sure to delete ${video.title}?`,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => deleteVideo(video, index)
        });
    }

    const deleteVideo = (video: Video, index: number) => {
        let result = VideoUtility.deleteVideo(video.id!);
        result.then(
            () => {
                state.videoData?.data?.splice(index, 1);
                dispatch({type:  Actions.LOAD_VIDEOS, payload: state.videoData });
            },
            err => console.log(err)
        )
    }

    return (
        <div style={{width: "20%", padding: 15, overflowY: "auto", height: "100vh"}}>
            <div>
                <Search value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Video..."
                    onSearch={searchVideos} allowClear enterButton
                    style={{marginBottom: 20}} />
            </div>
            <Skeleton active loading={state.loadingVideos}>
                {state.videos.map((video: Video, index: number) => {
                    return (
                        <VideoCard video={video} key={video.id} 
                            onClick={() => handleVideoClick(video)}
                            deleteVideo={handleRemoveVideo}
                            index={index} />
                    )
                })}
                {state.videoData &&  <Pagination current={state.videoData.current_page} 
                    defaultPageSize={15}
                    pageSize={state.videoData.per_page}
                    total={state.videoData.total}
                    pageSizeOptions={["1","5","10","15","20","40","50","100"]}
                    showSizeChanger={true}
                    hideOnSinglePage={true}
                    onShowSizeChange={paginatorSizeChange}
                    onChange={paginatorPageChange}
                    size="small" />}
            </Skeleton>
        </div>
    )
}

export default Videos;