import React, { useRef, useContext, useEffect, useState } from 'react';
import { PlayerStateContext, PlayerDispatchContext } from '../../Context/PlayerContext';
import { Button, Table, Popconfirm, Input, TimePicker, notification, Modal } from 'antd';
import {EditOutlined} from "@ant-design/icons";
import TimeSpan from './Utilities/TimeSpan';
import { Segment } from '../../Models/Segment';
import Actions from '../../Reducers/Actions';
import VideoUtility from './Utilities/VideoUtility';
import moment from 'moment';

const { RangePicker } = TimePicker;
const { TextArea } = Input;

const SegmentTable: React.FC = () => {
    const inputRef = useRef<any>(null);
    const { state } = useContext(PlayerStateContext);
    const { dispatch } = useContext(PlayerDispatchContext);
    const [currentSegment, setCurrentSegment] = useState<Segment>();
    const [showModal, setShowModal] = useState(false);
    const [note, setNote] = useState("");

    useEffect(() => {
        console.log(state.markSegments);
    }, [state.markSegments]);

    const startCellHandler = (record: Segment, rowIndex: number) => {
        return {
            onDoubleClick: (e: any) => {
                enableEditing("start", rowIndex, true);
                // if(inputRef.current)
                //     inputRef.current.focus();
            }
        }
    }

    const endCellHandler = (record: Segment, rowIndex: number) => {
        return {
            onDoubleClick: (e: any) => {
                enableEditing("end", rowIndex, true);
                // if(inputRef.current)
                //     inputRef.current.focus();
            }
        }
    }

    const noteCellHandler = (record: Segment, rowIndex: number) => {
        return {
            onDoubleClick: (e: any) => {
                enableEditing("note", rowIndex, true);
                // if(inputRef.current)
                //     inputRef.current.focus();
            }
        }
    }

    const enableEditing = (type: string, index: number, is_editing: boolean) => {
        let isStartEditing = false;
        let isEndEditing = false;
        let isNoteEditing = false;

        switch (type) {
            case "start":
                isStartEditing = is_editing;
                isEndEditing = false;
                isNoteEditing = false;
                break;
            case "end":
                isStartEditing = false;
                isEndEditing = is_editing;
                isNoteEditing = false;
                break;
            case "note":
                isStartEditing = false;
                isEndEditing = false;
                isNoteEditing = is_editing;
                break;
        }

        state.markSegments.forEach((seg: Segment, idx) => {
            seg.is_editing_note = false;
            seg.is_editing_start = false;
            seg.is_editing_end = false;
        });

        state.markSegments[index].is_editing_start = isStartEditing;
        state.markSegments[index].is_editing_end = isEndEditing;
        state.markSegments[index].is_editing_note = isNoteEditing;
        dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
    }

    const removeSegment = (index: number) => {
        if (state.markSegments[index].id) {
            let res = VideoUtility.deleteSegment(state.markSegments[index].id!);
            res.then(
                () => {
                    state.markSegments.splice(index, 1);
                    dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
                    notification.success({
                        message: "Segment successfully removed.",
                        duration: 5
                    })
                }
            );
        } else {
            state.markSegments.splice(index, 1);
            dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
            notification.success({
                message: "Segment successfully removed.",
                duration: 5
            })
        }
    }

    const cancelMarking = (index: number) => {
        dispatch({ type: Actions.UPDATE_MARK_STARTED, payload: false });
        removeSegment(index);
    }

    const noteOnKeydownHandler = (event: any, index: number) => {
        if (event.keyCode === 13 || event.keyCode === 27) {
            state.markSegments[index].is_editing_note = false;
            state.markSegments[index].is_editing_start = false;
            state.markSegments[index].is_editing_end = false;
            dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
            if (inputRef.current)
                inputRef.current.blur();
            return;
        }
    }

    const startInputChangeHandler = (time: moment.Moment | null, timeString: string, index: number) => {
        state.markSegments[index].start = TimeSpan.hmsToSeconds(timeString);
        state.markSegments[index].is_editing_start = false;
        dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
    }

    const endInputChangeHandler = (time: moment.Moment | null, timeString: string, index: number) => {
        state.markSegments[index].end = TimeSpan.hmsToSeconds(timeString);
        state.markSegments[index].is_editing_end = false;
        dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
    }

    const noteInputChangeHandler = (event: any, index: number) => {
        state.markSegments[index].note = event.target.value;
        dispatch({ type: Actions.UPDATE_MARK_SEGMENT, payload: state.markSegments });
    }

    const playSegment = (start: number, end: number) => {
        // player.seekTo(start);
        // player.playVideo();
        state.player?.loadVideoById({ videoId: state.currentVideoId!, startSeconds: start, endSeconds: end });
        dispatch({ type: Actions.UPDATE_SLIDER_VALUE, payload: [start, end] });
    }

    const editNote = (segment: Segment) => {
      setCurrentSegment(segment);
      setNote(segment.note);
      setShowModal(true);
    }

    const updateNote = () => {
      currentSegment!.note = note;
      setShowModal(false);
    }

    // antd has problem with their types!!!
    const columns: any = [
        {
            title: "Start",
            dataIndex: "start",
            key: "start",
            width: '150px',
            onCell: startCellHandler,
            render: (time: number, record: Segment, index: number) => <div>
                {record.is_editing_start && <TimePicker value={moment(TimeSpan.secondsToHms(time), time > 3600 ? "HH:mm:ss" : "mm:ss")}
                    ref={inputRef}
                    format={time > 3600 ? "HH:mm:ss" : "mm:ss"}
                    onKeyDown={(e) => noteOnKeydownHandler(e, index)}
                    onChange={(inputTime, timeString) => startInputChangeHandler(inputTime, timeString, index)}
                    style={{ width: 125 }} />}
                {!record.is_editing_start && <p>{TimeSpan.secondsToHms(time)}</p>}
            </div>
        },
        {
            title: "End",
            dataIndex: "end",
            key: "end",
            width: '150px',
            onCell: endCellHandler,
            render: (time: number, record: Segment, index: number) => <div>
                {record.is_editing_end && <TimePicker value={moment(TimeSpan.secondsToHms(time), time > 3600 ? "HH:mm:ss" : "mm:ss")}
                    ref={inputRef}
                    format={time > 3600 ? "HH:mm:ss" : "mm:ss"}
                    onKeyDown={(e) => noteOnKeydownHandler(e, index)}
                    onChange={(inputTime, timeString) => endInputChangeHandler(inputTime, timeString, index)}
                    style={{ width: 125 }} />}
                {!record.is_editing_end && <p>{TimeSpan.secondsToHms(time)}</p>}
            </div>
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            editable: true,
            onCell: noteCellHandler,
            render: (text: string, record: Segment, index: number) => <div>
                {record.is_editing_note && <Input value={record.note} ref={inputRef}
                    onKeyDown={(e) => noteOnKeydownHandler(e, index)}
                    onChange={(e) => noteInputChangeHandler(e, index)} />}
                {!record.is_editing_note && <p style={{marginLeft: 25}}>{record.note}</p>}
                <Button style={{position: 'absolute', top: 10, left: 0}} size='small' icon={<EditOutlined />} onClick={() => editNote(record)}></Button>
            </div>
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: "250px",
            render: (text: string, record: Segment, index: number) => <div>
                <a href={record.audio} download={`Segment` + (index + 1) + '.mp3'}>
                  <Button type="link" size="small">Download</Button>
                </a>
                <Button type="link" size="small" onClick={() => playSegment(record.start, record.end!)} style={{ marginRight: 15 }}>Play</Button>
                {(!state.markStarted) && <Popconfirm
                    title="Are you sure to delete this segment?"
                    onConfirm={(e) => removeSegment(index)}
                    okText="Yes"
                    cancelText="No"
                ><Button danger type="link" size="small">Remove</Button></Popconfirm>}
                {(state.markStarted && state.markSegments.length - 1 !== index) && <Popconfirm
                    title="Are you sure to delete this segment?"
                    onConfirm={(e) => removeSegment(index)}
                    okText="Yes"
                    cancelText="No"
                ><Button danger type="link" size="small">Remove</Button></Popconfirm>}
                {(state.markStarted && state.markSegments.length - 1 === index) && <Button danger type="link" size="small" onClick={(e) => cancelMarking(index)} >Cancel</Button>}
            </div>
        }
    ];

    return (
        <div>
            <Table columns={columns} dataSource={state.markSegments}
                pagination={false} size="small" />
                <Modal visible={showModal} title="Edit Note" onCancel={() => setShowModal(false)} onOk={updateNote}>
                  {currentSegment && <TextArea value={note} onChange={(e) => setNote(e.target.value)}></TextArea>}
                </Modal>
        </div>
    )
}

export default SegmentTable;