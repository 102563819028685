import { Error } from "../Models/Error";

interface IHttpRequestErrorData {
    response?: {
        data: Error
    }
}

export const HttpRequestErrorHandler = (error: IHttpRequestErrorData, 
    callback: (message: string) => void) => {

    if(error.response) {
        callback(error.response.data.error_description);
    } else {
        console.log(error);
        callback('Something went wrong.');
    }
}