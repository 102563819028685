import axios, { AxiosResponse } from 'axios';

class BaseClient {

    baseUrl = "https://videoapi.iguides.net/v1/api";
    // baseUrl = "http://localhost:8000/api";

    get = (uri: string, options?: object): Promise<AxiosResponse<any>> => {
        return axios.get(this.baseUrl + uri, options);
    }

    post = (uri: string, data: object, options?: object): Promise<AxiosResponse<any>> => {
        return axios.post(this.baseUrl + uri, data, options);
    }

    put = (uri: string, data: object, options?: object): Promise<AxiosResponse<any>> => {
        return axios.put(this.baseUrl + uri, data, options);
    }

    delete = (uri: string, options?: object): Promise<AxiosResponse<any>> => {
        return axios.delete(this.baseUrl + uri, options);
    }

}

export default BaseClient;