import VideoClient from "../../../Client/Video";
import { Video } from "../../../Models/Video";
import { ScrapeData } from "../../../Models/ScrapeData";
import Scraper from "../../../Client/Scraper";
import { HttpRequestErrorHandler } from "../../../ErrorHandler/ErrorHandlers";

class VideoUtility {

    // load all the videos
    loadVideos = ( params: {
        search?: string,
        limit?: number,
        sort_by?: string,
        order?: string,
        page?:number
    }): Promise<any> => {
        let res = new Promise(async (resolve, reject) => {
            try {
                let videos = await VideoClient.getVideos(params);
                if(videos.status === 200)
                    resolve(videos.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
        });

        return res;
        
    }

    saveVideo = async (video: Video): Promise<Video> => {
        let res = await new Promise<Video>(async (resolve, reject) => {
            try {
                let vid = await VideoClient.create(video);
                if(vid.status === 200)
                    resolve(vid.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
            
        });

        return res;
    }

    updateVideo = async (id: number, video: Video): Promise<Video> => {
        let res = await new Promise<Video>(async (resolve, reject) => {
            try {
                let vid = await VideoClient.update(id, video);
                if(vid.status === 200)
                    resolve(vid.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
            
        });

        return res;
    }

    scrapeYoutubeVideo = (youtubeUrl: string): Promise<ScrapeData> => {
        let res = new Promise<ScrapeData>(async (resolve, reject) => {
            try {
                let data = await Scraper.scrape(youtubeUrl);
                if(data.status === 200)
                    resolve(data.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
        });

        return res;
    }

    getVideoInfo = (youtubeId: string): Promise<Video> => {
        let res = new Promise<Video>(async (resolve, reject) => {
            try {
                let data = await VideoClient.getVideo(youtubeId);
                if(data.status === 200)
                    resolve(data.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
        });

        return res;
    }

    deleteVideo = (videoId: number): Promise<any> => {
        let res = new Promise<Video>(async (resolve, reject) => {
            try {
                let data = await VideoClient.deleteVideo(videoId);
                if(data.status === 200)
                    resolve(data.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
        });

        return res;
    }

    deleteSegment = (segmentId: number): Promise<any> => {
        let res = new Promise<Video>(async (resolve, reject) => {
            try {
                let data = await VideoClient.deleteSegment(segmentId);
                if(data.status === 200)
                    resolve(data.data);
            } catch(error: any) {
                HttpRequestErrorHandler(error, (message) => {
                    reject(message);
                });
            }
        });

        return res;
    }
}

export default new VideoUtility();