import { AxiosResponse } from "axios";
import BaseClient from "./BaseClient";
import { Video as Model } from '../Models/Video';

class Video extends  BaseClient {

    create = async (data: Model): Promise<AxiosResponse<any>> => {
        return await this.post("/video", data);
    }

    update = (id: number, data: Model): Promise<AxiosResponse<any>> => {
        return this.put(`/video/${id}`, data);
    }

    deleteVideo = (id: number): Promise<AxiosResponse<any>> => {
        return this.delete(`/video/${id}`);
    }

    getVideo = (id: string): Promise<AxiosResponse<any>> => {
        return this.get(`/video/${id}`);
    }

    getVideos = async (params: {
        search?: string,
        limit?: number,
        sort_by?: string,
        order?: string,
        page?:number
    }): Promise<AxiosResponse<any>> => {
        return await this.get("/video", { params });
    }

    deleteSegment = (id: number): Promise<AxiosResponse<any>> => {
        return this.delete(`/segment/${id}`);
    }

}

export default new Video();